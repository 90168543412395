import styles from "./conversion-bar.module.css"
import { alreadyShown, updateWidgetShown } from "@lib/sfc-widgets"
import { getBreakpoint } from "@lib/utils"

const CONVERSION_BAR_WIDGET_ID = 3

let conversionBarAdLoaded = false
let isOpen = false

const getConversionBarContainer = () => {
    return document.querySelector("#conversionBarContainer")!
}

const getConversionBarAdSlot = () => {
    return document.querySelector("#slot-adConversionBar")!
}

const openConversionBar = () => {
    if (isOpen) {
        return
    }

    getConversionBarContainer().classList.add(styles.conversionBarContainerOpen)
    updateConversionBarShown()
    isOpen = true
}

const hasScrolledEnough = () => {
    const scrollPercent = window.scrollY / (document.body.offsetHeight - window.innerHeight)
    return scrollPercent > 0.25
}

const openConversionBarIfReady = () => {
    if (conversionBarAdLoaded && hasScrolledEnough()) {
        openConversionBar()
    }
}

const closeConversionBar = () => {
    getConversionBarContainer().classList.remove(styles.conversionBarContainerOpen)
}

const conversionBarHasAlreadyShown = (): boolean => {
    return alreadyShown(CONVERSION_BAR_WIDGET_ID)
}

const updateConversionBarShown = () => {
    updateWidgetShown(CONVERSION_BAR_WIDGET_ID)
}

const init = () => {
    const breakpoint = getBreakpoint()
    if (
        !(breakpoint === "desktop" || breakpoint === "large-desktop") ||
        conversionBarHasAlreadyShown()
    ) {
        return
    }

    const conversionBarContainer = getConversionBarContainer()
    conversionBarContainer.classList.remove("hidden")
    conversionBarContainer.classList.add("flex")

    const conversionBarAdSlot = getConversionBarAdSlot()

    googletag.cmd.push(() => {
        googletag.pubads().addEventListener("slotRenderEnded", (event) => {
            if (
                event.slot.getSlotElementId() === conversionBarAdSlot.id &&
                event.isEmpty === false
            ) {
                conversionBarAdLoaded = true

                // Just wait a bit to make sure that the iframe is fully rendered.
                setTimeout(() => {
                    openConversionBarIfReady()
                }, 2000)
            }
        })
    })

    window.addEventListener("scroll", () => {
        openConversionBarIfReady()
    })

    document.getElementById("conversionBarCloseButton")!.addEventListener("click", (e) => {
        closeConversionBar()
        e.preventDefault()
    })
}

init()
