///
/// Handling the legacy widgets tool
///

import Cookies from 'js-cookie'

const NUMBER_OF_VISITS_COOKIE_NAME = "sfc-widgets-number-of-visits"
const WIDGETS_SHOWN_COOKIE_NAME = 'sfc-widget-widgets-shown'

const updateNumberOfVisits = () => {
    const cookieValue = Cookies.get(NUMBER_OF_VISITS_COOKIE_NAME)

    if (cookieValue === undefined) {
        const currentDate = new Date()
        const newDate = new Date(currentDate.getTime() + 3 * 60000)
        Cookies.set(NUMBER_OF_VISITS_COOKIE_NAME, '1', {
            expires: newDate
        })
    } else {
        const value = parseInt(cookieValue)
        const currentDate = new Date()
        const newDate = new Date(currentDate.getTime() + 2 * 60000)
        Cookies.set(NUMBER_OF_VISITS_COOKIE_NAME, (value + 1).toString(), {
          expires: newDate
        });
    }
}

const getWidgetsShown = (): string => {
    return Cookies.get(WIDGETS_SHOWN_COOKIE_NAME) || ''
}

const alreadyShown = (widgetId: number): boolean => {
    return (
        getWidgetsShown()
            .replace(/\s/g, '')
            .split(',')
            .find((id) => id === widgetId.toString()) !== undefined
    )
}

const updateWidgetShown = (widgetId: number) => {
    if (!alreadyShown(widgetId)) {
        Cookies.set(
            WIDGETS_SHOWN_COOKIE_NAME,
            getWidgetsShown() !== ''
                ? `${getWidgetsShown()}, ${widgetId}`
                : widgetId.toString()
        )
    }
}

const initSfcWidgets = () => {
    updateNumberOfVisits()
}

export { initSfcWidgets, alreadyShown, updateWidgetShown }
