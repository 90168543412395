import styles from "./welcome-ad.module.css"
import { alreadyShown, updateWidgetShown } from "@lib/sfc-widgets"
import { disableScroll, enableScroll, getBreakpoint } from "@lib/utils"

const WELCOME_AD_WIDGET_ID = 2

let welcomeAdAdSlotAdLoaded = false
let isOpen = false
let remainingSeconds = 15

const getWelcomeAdContainer = () => {
    return document.querySelector("#welcomeAdContainer")!
}

const getWelcomeAdAdSlot = () => {
    return document.querySelector("#slot-adWelcomeAd")!
}

const getWelcomeAdRemainingSecondsElement = () => {
    return document.querySelector("#welcomeAdRemainingSeconds")!
}

const openWelcomeAd = () => {
    if (isOpen) {
        return
    }

    getWelcomeAdContainer().classList.add(styles.welcomeAdContainerOpen)
    updateWelcomeAdShown()
    isOpen = true

    disableScroll()

    updateRemainingSecounds()
}

const openWelcomeAdIfReady = () => {
    if (welcomeAdAdSlotAdLoaded) {
        openWelcomeAd()
    }
}

const updateRemainingSecounds = () => {
    const remainingSecondsElement = getWelcomeAdRemainingSecondsElement()
    remainingSecondsElement.textContent = remainingSeconds.toString()

    remainingSeconds--

    if (remainingSeconds < 0) {
        closeWelcomeAd()
    } else {
        setTimeout(() => {
            updateRemainingSecounds()
        }, 1000)
    }
}

const closeWelcomeAd = () => {
    getWelcomeAdContainer().classList.remove(styles.welcomeAdContainerOpen)

    enableScroll()
}

const welcomeAdHasAlreadyShown = (): boolean => {
    return alreadyShown(WELCOME_AD_WIDGET_ID)
}

const updateWelcomeAdShown = () => {
    updateWidgetShown(WELCOME_AD_WIDGET_ID)
}

const init = () => {
    if (getBreakpoint() === "mobile" || welcomeAdHasAlreadyShown()) {
        return
    }

    const welcomeAdContainer = getWelcomeAdContainer()
    welcomeAdContainer.classList.remove("hidden")
    welcomeAdContainer.classList.add("flex")

    const welcomeAdAdSlot = getWelcomeAdAdSlot()

    googletag.cmd.push(() => {
        googletag.pubads().addEventListener("slotRenderEnded", (event) => {
            if (event.slot.getSlotElementId() === welcomeAdAdSlot.id && event.isEmpty === false) {
                welcomeAdAdSlotAdLoaded = true

                // Just wait a bit to make sure that the iframe is fully rendered.
                setTimeout(() => {
                    openWelcomeAdIfReady()
                }, 2000)
            }
        })
    })

    document.getElementById("welcomeAdCloseButton")!.addEventListener("click", (e) => {
        closeWelcomeAd()
        e.preventDefault()
    })

    document.getElementById("welcomeAdContinueButton")!.addEventListener("click", (e) => {
        closeWelcomeAd()
        e.preventDefault()
    })
}

init()
