import Cookies from "js-cookie"
import styles from "./hello-bar.module.css"
import type { CookieAttributes } from "node_modules/@types/js-cookie"
import { getBreakpoint } from "@lib/utils"

const COOKIE_NAME = "sfc-hello-bar"

let helloBarAdLoaded = false

const getHelloBarContainer = () => {
    return document.querySelector("#helloBarContainer")
}

const getHelloBarAdSlot = () => {
    return document.querySelector("#slot-adHelloBar")
}

const openHelloBar = () => {
    getHelloBarContainer()?.classList.add(styles.helloBarContainerOpen)
}

const openHelloBarIfReady = () => {
    if (helloBarAdLoaded) {
        openHelloBar()
    }
}

const closeHelloBar = () => {
    getHelloBarContainer()?.classList.remove(styles.helloBarContainerOpen)

    const cookieOptions: CookieAttributes = {
        expires: 0.5
    }

    if (window.location.hostname.includes("savingforcollege.com")) {
        cookieOptions.domain = "savingforcollege.com"
        cookieOptions.path = "/"
    }

    Cookies.set(COOKIE_NAME, "true", cookieOptions)
}

const isDismissed = () => {
    return Cookies.get(COOKIE_NAME)
}

const init = () => {
    if (getBreakpoint() === "mobile" || isDismissed()) {
        return
    }

    const helloBarContainer = getHelloBarContainer()
    helloBarContainer?.classList.remove("hidden")
    helloBarContainer?.classList.add("flex")

    const helloBarAdSlot = getHelloBarAdSlot()

    googletag.cmd.push(() => {
        googletag.pubads().addEventListener("slotRenderEnded", (event) => {
            if (event.slot.getSlotElementId() === helloBarAdSlot?.id && event.isEmpty === false) {
                helloBarAdLoaded = true
                
                // Just wait a bit to make sure that the iframe is fully rendered.
                setTimeout(() => {
                    openHelloBarIfReady()
                }, 2000)
            }
        })
    })

    document.getElementById("helloBarCloseButton")?.addEventListener("click", (e) => {
        closeHelloBar()
        e.preventDefault()
    })

    openHelloBarIfReady()
}

init()
