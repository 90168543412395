function setEmailSignUpEventListeners() {
  getEmailSignUpButton()?.addEventListener('click', (e) => {
    signUp()
    e.preventDefault()
  })

  getEmailInput()?.addEventListener('keypress', (e) => {
    if (e.key === 'Enter') {
      signUp()
      e.preventDefault()
    }
  })

  getEmailInput()?.addEventListener('input', () => {
    const emailInput = getEmailInput()
    if (validateEmail(emailInput.value)) {
      getEmailSignUpButton().disabled = false
    } else {
      getEmailSignUpButton().disabled = true
    }
  })
}

async function signUp() {
  const emailInput = getEmailInput()
  const email = emailInput.value

  if (!email) {
    return
  }

  try {
    await fetch(new URL(import.meta.env.PUBLIC_GRAPHQL_URL), {
      method: 'post',
      body: JSON.stringify({
        query: `mutation {
                        track_data(email: "${email}", email_source: "footer", source: "newsletter", campaign: "newsletters", consumer_newsletter: "Yes")
                    }`
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    handleSignUpSuccess()
  } catch (error) {
    console.error(error)
  }
}

function validateEmail(email: string): boolean {
  return /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

function handleSignUpSuccess() {
  const emailInput = getEmailInput()
  emailInput.value = ''

  document.getElementById('footerEmailSignUpSuccessMessage')!.classList.remove('hidden')
}

function getEmailInput(): HTMLInputElement {
  return document.getElementById('footerEmailInput')! as HTMLInputElement
}

function getEmailSignUpButton(): HTMLButtonElement {
  return document.getElementById('footerEmailSignUpButton')! as HTMLButtonElement
}

setEmailSignUpEventListeners()
